$(document).ready(() => {
	// Toggle Whole Sidebar
	$('#sidetogbtn').on('click', (e) => {
		$('.sidebar').toggleClass('hide');
		e.preventDefault();
	});

	// Toggle Sidebar to Iconbar
	$('.sidebar-toggler').on('click', (e) => {
		$('.sidebar').toggleClass('sidebar-narrow-unfoldable');
		e.preventDefault();
	});

	/* IconBar Toggle */
	$(document).ready(() => {
		$('.sidebar-icon-navbar a').on('click', (e) => {
			// console.log(e.currentTarget.id);
			$('.sidebar-icon-navbar a').removeClass('active');
			$(`#${e.currentTarget.id}`).addClass('active');
			$('.sidebar-navigation .tab-pane').removeClass('show active');
			$(`#${e.currentTarget.id}-nav`).addClass('show active');
			e.preventDefault();
		});

		$('.nav-item a').click(function () {
			$('.nav-item a').removeClass('active');
			$(this).addClass('active');
		});
	});

	// Toggle Nav Group
	$('.nav-group-toggle').on('click', function (e) {
		const self = $(this).parent();
		$('.nav-group').not(self).removeClass('show');
		self.toggleClass('show');
		e.preventDefault();
	});

	// Finding Active Navigation on Page Load
	// const currentPathname = window.location.pathname;
	// const currentPathnameArray = window.location.pathname.split('/');
	// let lastIndex = currentPathname.length;
	// let strCurrentPathname;
	// let status = 'continue';
	/* for (let i = 0; i < currentPathnameArray.length; i++) {
		// console.log(status);
		if (status === 'break') {
			// console.log("calling break"); // break on first url match
			break;
		}
		strCurrentPathname = currentPathname.substring(0, lastIndex);
		lastIndex = strCurrentPathname.lastIndexOf('/');
		// console.log(strCurrentPathname);

		// eslint-disable-next-line no-loop-func
		$('.tab-content a').each(function () {
			// console.log(this.pathname);
			// console.log(strCurrentPathname);
			if (this.pathname === strCurrentPathname) {
				// console.log("success");
				status = 'break';
				$(this).addClass('active');
				$(this).closest('.nav-group').addClass('show');
				$(this).closest('.tab-pane').addClass('show active');
				const findID = $(this).closest('.tab-pane').attr('id');
				const iconBarID = findID.replace('-nav', '');
				// console.log(iconBarID);
				$('.sidebar-icon-navbar a')
					.not($(`#${iconBarID}`))
					.removeClass('active');
				$(`#${iconBarID}`).addClass('active');
				return false;
			}
			return 1;
		});
	} */

	// To Make Responsive Footer
	// Check if main have scroll then add class to footer. fixed bottom when no scroll
	// Fix for Footer Position
	// const main = document.querySelector('.simplebar-placeholder');
	// console.log(main);
	// console.log(main.scrollHeight);
	// console.log(main.clientHeight);
	// if (main.scrollHeight > main.clientHeight) {
	// 	$('footer').removeClass('fixedbottom');
	// } else {
	// 	// main is small so fixedbottom
	// 	$('footer').addClass('fixedbottom');
	// 	$('footer').css('width', main.clientWidth);
	// 	$(window).resize(() => {
	// 		$('footer').css('width', main.clientWidth);
	// 	});
	// }

	// // Fix for switching between sidetoggle and offcanvas
	// $(window).resize(() => {
	// 	if ($(window).width() < 992) {
	// 		$('.sidebar').removeClass('hide sidebar-narrow-unfoldable');
	// 	} else {
	// 		$('.sidebar').addClass('visible');
	// 	}
	// });
});
